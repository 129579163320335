<script>
import Web3 from 'web3';
import Button from '@/components/Button';
import InputComponent from '@/components/InputComponent';
import cow from '@/assets/img/cow.svg';
import closeIcon from '@/assets/img/close-icon.svg';
import { send as sendProject } from '@/helpers/api/galaxy.api';
import { isValidHttpUrl } from '@/utils/validators';

export default {
  name: 'AddProject',
  components: {
    Button,
    InputComponent,
  },
  props: ['address', 'show'],
  data() {
    return {
      form: {
        description: '',
        name: '',
        smartcontract: '',
        website: '',
        icon: '',
      },
      loading: false,
      error: null,
      cow,
      closeIcon,
    };
  },
  methods: {
    send() {
      if (!this.loading) {
        const isEmpty = Object.values(this.form).every((x) => (x === null || !x));
        if (isEmpty) {
          this.error = this.$t('errors.fill-all');
        } else if (!Web3.utils.isAddress(this.form.smartcontract)) {
          this.error = this.$t('errors.contract');
        } else if (!isValidHttpUrl(this.form.website)) {
          this.error = this.$t('errors.website');
        } else {
          this.loading = true;
          sendProject({
            ...this.form,
            address: this.address,
          }).then(() => {
            this.loading = false;
            this.$emit('update');
            this.$modal.hide('add-project-modal');
          }).catch(() => {
            this.error = this.$t('errors.default');
            this.loading = false;
          });
        }
      }
    },
  },

};
</script>

<template>
  <v-dialog
    @input="$emit('update:show', $event)"
    :value="show"
  >
    <div class="modal main-block modal--light">
      <div class="modal__close">
        <img :src="closeIcon" @click="$emit('update:show', false)">
      </div>
      <h4 class="modal__ttl">{{$t('add-project.add')}}</h4>

      <InputComponent
          :placeholder="$t('add-project.placeholder.project')"
          v-model="form.name" >
        <template v-slot:label>
          {{ $t('add-project.name') }}
        </template>
      </InputComponent>

      <InputComponent
          :placeholder="$t('add-project.placeholder.desc')"
          :textarea="true"
          v-model="form.description" >
        <template v-slot:label>
          {{ $t('add-project.description') }}
        </template>
      </InputComponent>

      <InputComponent
          :placeholder="$t('add-project.placeholder.link')"
          v-model="form.website" >
        <template v-slot:label>
          {{ $t('add-project.link') }}
        </template>
      </InputComponent>

      <InputComponent
          :placeholder="$t('add-project.placeholder.link-icon')"
          v-model="form.icon" >
        <template v-slot:label>
          {{ $t('add-project.link-icon') }}
        </template>
      </InputComponent>

      <InputComponent
          :placeholder="$t('add-project.smart')"
          v-model="form.smartcontract">
        <template v-slot:label>
          {{ $t('add-project.smart') }}
        </template>
      </InputComponent>

      <span class="error" v-if="error">{{error}}</span>
      <div class="modal__btns">
        <Button
          @click="send"
        >
          {{ $t('galaxy.apply') }}
        </Button>
      </div>
    </div>
  </v-dialog>
</template>

<style lang="scss">
.error {
  color: red;
}

.modal__close {
  img {
    width: 8.4rem;
    height: 8.4rem;
    cursor: pointer;
    object-fit: cover;
  }
}

.modal__ttl {
  margin-bottom: 2rem;
}
</style>
