const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};
// eslint-disable-next-line import/prefer-default-export
export { isValidHttpUrl };
