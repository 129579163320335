<script>
import { mapState, mapActions } from 'vuex';
import Button from '@/components/Button';
import InputComponent from '@/components/InputComponent';
import GalaxyCard from '@/components/GalaxyCard';
import AddProject from '@/components/Modals/AddProject';
import Confirm from '@/components/Modals/Confirm';
import galaxyHideDesc from '@/assets/img/galaxy-desc.svg';

export default {
  name: 'index',
  components: {
    AddProject,
    GalaxyCard,
    Button,
    Confirm,
    InputComponent,
  },
  data: () => ({
    galaxyHideDesc,
    projects: [],
    tx: null,
    search: '',
    showConfirm: false,
    showAddProject: false,
  }),
  computed: {
    ...mapState({
      ethAddress: (state) => state.User.ethAddress,
      list: (state) => state.Galaxy.list,
      isAddressBurned: (state) => state.Galaxy.isAddressBurned,
      isUserProduct: (state) => state.Galaxy.isUserProduct,
      milkBalance: (state) => state.Milk.milkBalance,
      quoteVote: (state) => state.Galaxy.quoteVote,
      isLoading: (state) => state.Galaxy.loading,
    }),
    filteredList() {
      return this.list.filter((post) => post.name.toLowerCase().includes(
        this.search.toLowerCase(),
      ));
    },
    apply() {
      if (!this.isLoading) {
        if (this.isAddressBurned) {
          if (this.isUserProduct) {
            return false;
          }
          return true;
        }
        return true;
      }
      return false;
    },
    isEnoughBal() {
      if (this.milkBalance?.comparedTo) {
        return this.milkBalance.comparedTo(this.quoteVote) > -1;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      giveOblivion: 'Galaxy/giveOblivion',
      getList: 'Galaxy/getList',
      getUserList: 'Galaxy/getUserList',
    }),
    openModal() {
      if (this.isAddressBurned) {
        this.showAddProject = true;
      } else {
        this.showConfirm = true;
      }
    },
    async addProject() {
      try {
        await this.giveOblivion(() => {
          this.showAddProject = true;
          this.showConfirm = false;
        });
      } catch {
        this.showAddProject = false;
        this.showConfirm = false;
      }
    },
    update() {
      this.getList();
      this.getUserList();
    },
  },
  created() {
    this.$store.dispatch('Galaxy/update');
  },
};
</script>

<template>
  <div class="page-content galaxy-page">
    <div class="main-wrap main-wrap--narrow galaxy">
      <h1 class="page-title">{{ $t('galaxy.page') }}</h1>
      <div :class="[!list.length ? 'empty' : '', 'galaxy-search']">
        <InputComponent
          class="galaxy-search__input"
          v-model="search"
          :placeholder="$t('galaxy.search')"
        />
        <Button
          @click="openModal"
          :disabled="!apply"
        >
           {{ $t('galaxy.apply') }}
         </Button>
      </div>
      <div class="galaxy-list" v-if="list.length">
        <div v-if="filteredList.length">
          <GalaxyCard
            :key="key"
            v-for="(project, key) in filteredList"
            :name="project.name"
            :image="project.icon"
            :address="project.smartcontract"
            :description="project.description"
            :link="project.website"
          />
        </div>
        <div v-else class="nothing-block">
          <span>{{$t('errors.search')}}</span>
        </div>
      </div>
    </div>
    <AddProject :address="ethAddress" @update="update" :show.sync="showAddProject"/>
    <Confirm
      :isEnoughBal="isEnoughBal"
      :isLoading="isLoading"
      :action="addProject"
      :type="'add'"
      :amount="quoteVote"
      :symbol="'MILK2'"
      :show.sync="showConfirm"
    />
  </div>
</template>

<style scoped lang="scss">
.galaxy {
  &-search {
    display: flex;
    justify-content: space-between;
    max-width: 75rem;
    margin: 0 auto;
    align-items: center;

    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
    }

    &__input {
      width: 70%;
      margin: 0;
      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
        margin-bottom: 4rem;
      }
    }
  }

  .galaxy-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
  }

  .nothing-block {
    display: flex;
    height: 20rem;
    font-size: 1.8rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #000E89;
  }
}
</style>
