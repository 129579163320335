<template>
  <div class="galaxy-card main-block">
    <div class="galaxy-card__content">
      <span class="galaxy-card__title">{{name}}</span>
      <p class="galaxy-card__description">
        {{description}}
      </p>
      <div class="link-container">
        <a target="_blank" :href="generateAddress(address)" class="address">
          <span>{{ shrinkString(getHashOnly(address)) }}</span>
        </a>
        <a  class="link" target="_blank" :href="link">
          {{link.replace('https://', '')}}
        </a>
      </div>
    </div>
    <div class="galaxy-card__avatar">
      <img v-if="image" :src="image"/>
    </div>
  </div>
</template>

<script>
import { shrinkString } from '../helpers/filters';

export default {
  props: ['name', 'description', 'address', 'link', 'image'],
  name: 'GalaxyCard',
  methods: {
    shrinkString,
    getHashOnly(link) {
      return link.split('address/')[1] || link;
    },
    generateAddress(link) {
      if (link.includes('http')) {
        return link;
      }
      return `https://etherscan.io/address/${link}`;
    },
  },
};
</script>

<style lang="scss">
.galaxy-card {
  display: flex;
  align-items: center;
  padding: 3em 4em;
  font-size: 1rem;
  position: relative;
  @media screen and (max-width: $breakpoint-md) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
    font-size: 1.2rem;
    padding: 2rem 3rem;
  }
  &:not(:first-child) {
    margin-top: 3em;
  }
  &:first-child {
    margin-top: 6em;
  }
  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13.5em;
    height: 13.5em;
    margin-left: 7.4em;
    margin-right: 7.4em;
    background: $btn-bg;
    border-radius: 50%;
    box-shadow: $pools-select-shadow;
    @media screen and (max-width: $breakpoint-sm) {
      order: -1;
    }
    img {
      width: 6em;
      height: 6em;
      object-fit: contain;
    }
  }
  &__description {
    font-weight: 500;
    font-size: 1.6em;
    color: $grey-font-color;
  }
  &__title {
    font-weight: 600;
    font-size: 2.8em;
    color: #000E89;
    margin-bottom: 1rem;
    @media screen and (max-width: $breakpoint-sm) {
      text-align: center;
    }
  }
  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
    .link {
      text-overflow: ellipsis;
      font-weight: 600;
      overflow: hidden;
      width: 50%;
      margin-left: 52px;
    }
    .link-container {
      margin-top: 1.8em;
      display: flex;
      align-items: center;
      @media screen and (max-width: $breakpoint-sm) {
        flex-direction: column;
      }
      a {
        font-weight: 600;
        font-size: 1.6em;
        color: #000E89;
        text-decoration: none;
        margin-left: 3em;
        @media screen and (max-width: $breakpoint-sm) {
          margin: 0 0 2em;
        }
      }
      .address {
        background: $btn-bg;
        height: 3em;
        display: flex;
        align-items: center;
        margin-left: 0;
        justify-content: center;
        box-shadow: $main-item-shadow;
        border-radius: 2.6em;
        padding: 0 1.5em;
        @media screen and (max-width: $breakpoint-sm) {
          order: 10;
        }
        span {
          font-weight: 600;
          font-size: 1em;
          color: #A0A5D5;
        }
      }
    }
  }
}
</style>
